import logo from './logo.svg';
import './App.css';
import Home from './pages/home'

function App() {
  return (
    <div className="App">
      <div style={{marginTop:'10px'}}>HPRAHI.COM | HIREN PANDYA RAHI</div>
      <Home />
    </div>
  );
}

export default App;
