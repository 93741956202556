import { blogs } from "../data/blogs";
let _blogs = [];
for(let i=blogs.length-1;i>0;i--) {
    _blogs.push(blogs[i]);
}
function removeTags(str) {
    if ((str===null) || (str===''))
        return false;
    else
        str = str.toString();
          
    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace( /(<([^>]+)>)/ig, '');
}
const Home = () => {
    return (
        <>
            {_blogs.map((blog,index)=>{
                return (
                    <div style={{borderTop: '1px solid #ccc',margin: '10px', padding: '30px 10px'}}>
                        <div style={{fontWeight:'bold'}}>
                            {blog.name}
                        </div> 
                        <div style={{fontSize:'11px'}}>
                            {blog.date}
                        </div>
                        <div style={{marginTop: '20px',whiteSpace: 'pre-wrap'}}>
                            {removeTags(blog.body)}
                        </div>
                        <div style={{marginTop:'10px'}}>
                            {blog.by}
                        </div>
                        {blog.ps && 
                            <div style={{marginTop:'20px'}}>
                                {blog.ps}
                            </div>
                        }
                    </div>
                )
            })}
        </>
    )
}
export default Home;